import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

class Toast {
    toastError(errorMessage) {
        if (errorMessage) {
            var toaster = Toastify({
                text: errorMessage,
                duration: 5000,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                backgroundColor: "linear-gradient(to right, #dc3545, #dc3545)",
                stopOnFocus: true // Prevents dismissing of toast on hover
                //onClick: function(){} // Callback after click
            });
            toaster.showToast();
        } else {
            console.warn("No errorMessage passed to toastError")
        }
    }

    toastSuccess(successMessage) {
        if (successMessage) {
            var toaster = Toastify({
                text: successMessage,
                duration: 2000,
                close: false,
                gravity: "bottom", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                backgroundColor: "linear-gradient(to right, #28a745, #28a745)",
                stopOnFocus: false // Prevents dismissing of toast on hover
                //onClick: function(){} // Callback after click
            });
            toaster.showToast();
        } else {
            console.warn("No successMessage passed to toastSuccess")
        }
    }

    toastWarning(warningMessage) {
        if (warningMessage) {
            var toaster = Toastify({
                text: warningMessage,
                duration: 5000,
                close: true,
                gravity: "top", // `top` or `bottom`
                position: "center", // `left`, `center` or `right`
                backgroundColor: "linear-gradient(to right, #856404, #856404)",
                stopOnFocus: true, // Prevents dismissing of toast on hover
                //onClick: function(){} // Callback after click
            });
            toaster.showToast();

        } else {
            console.warn("No warningMessage passed to toastWarning")
        }
    }

    toastInfo(infoMessage) {
        if (infoMessage) {
            var toaster = Toastify({
                text: infoMessage,
                duration: 2000,
                close: false,
                gravity: "bottom", // `top` or `bottom`
                position: "right", // `left`, `center` or `right`
                backgroundColor: "linear-gradient(to right, #28a745, #28a745)",
                stopOnFocus: false, // Prevents dismissing of toast on hover
                //onClick: function(){} // Callback after click
            });

            toaster.showToast();
        } else {
            console.warn("No infoMessage passed to toastInfo")
        }
    }
}

export default new Toast()
